@font-face {
    font-family: Lato-Black;
    src: url('./fonts/Lato/Lato-Black.ttf');
}

@font-face {
    font-family: Lato-BlackItalic;
    src: url('./fonts/Lato/Lato-BlackItalic.ttf');
}

@font-face {
    font-family: Lato-Bold;
    src: url('./fonts/Lato/Lato-Bold.ttf');
}

@font-face {
    font-family: Lato-Regular;
    src: url('./fonts/Lato/Lato-Regular.ttf');
}